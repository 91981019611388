import React, { useContext, useRef } from 'react';
import TextField from '@mui/material/TextField';
import Autocomplete from '@mui/material/Autocomplete';
import { StyledDataGrid } from './styled';
import PropTypes from 'prop-types';
import { Controller, useFormContext } from 'react-hook-form';
import { StyledTextField } from '../FormElementComponent/styled';
import { Box, Checkbox, Chip, FormHelperText, Tooltip } from '@mui/material';
import { protectedResources } from '../../features/auth/config';
import useFetchWithMsal from '../../hooks/useFetchWithMsal';
import { ThemeContext } from '../../ThemeContext';
import { UserContext, USER_TYPE_SUPER } from 'features/auth/userContext';

// eslint-disable-next-line react/prop-types
function EditableGrid({ teamGroup, rows, setRows, fields, autoFoucusFlag }) {
  const userInfo = useContext(UserContext);
  // const [options, setOptions] = useState([]);
  const { teamMemberOptions, setTeamMemberOptions } = useContext(ThemeContext);
  const timeoutRef = useRef(null);
  // const [rows, setRows] = useState([]);
  const { control } = useFormContext();
  const { execute } = useFetchWithMsal();
  const textFieldRef = useRef();
  // const inputRef = React.useRef(null);

  const handleCellEdit = (rowIndex, field, value) => {
    // Update the fields array
    // console.log('lets test row index field value', rowIndex, field, value);
    // console.log('lets also test fields', fields);
    fields[rowIndex][field] = value;
  };
  const handleInputChange = async (event, newInputValue) => {
    if (newInputValue === '') {
      return;
    }
    // Clear any existing timeout
    if (timeoutRef.current) {
      clearTimeout(timeoutRef.current);
    }

    // Set a new timeout to call the API after the user has stopped typing for 500ms
    timeoutRef.current = setTimeout(async () => {
      try {
        const response = await execute(
          'GET',
          protectedResources.apiCreateList.getCommonVendorEndpoint(newInputValue)
        );

        setTeamMemberOptions((prev) => {
          const newArray = [...prev, ...response];
          return newArray.filter((obj, index, arr) => {
            let isActive = true;
            if (userInfo?.userType !== USER_TYPE_SUPER) {
              isActive = obj?.isActive === 'Y' ? true : false;
            }
            return index === arr.findIndex((o) => o.id === obj.id) && isActive;
          });
        });

        // Reset the value of timeoutRef.current
        timeoutRef.current = null;
      } catch (error) {
        // console.log('lets see the error', error);
      }

      // Call your API with the new input value
      // const response = await fetch(`/my-api?query=${newInputValue}`);
      // const data = await response.json();
    }, 1500);
  };

  const countries = [];
  const handleRowSelection = (rowId) => {
    const updatedRows = rows.map((row) => {
      if (row.id === rowId) {
        return { ...row, selected: !row.selected };
      }
      return row;
    });
    // console.log("lets print updated rows",updatedRows)
    setRows(updatedRows);
  };
  function isTextOverflowing(element) {
    // console.log('lets print element', element.offsetWidth, element.scrollWidth);
    return element.offsetWidth < element.scrollWidth;
  }

  const columns = [
    {
      field: 'selected',
      headerName: 'Select',
      sortable: false,
      width: 60,
      renderCell: (params) => (
        <Controller
          name={`submission.teamMembers.${params.id - 1}.selected`}
          control={control}
          render={({ field: { onChange, value }, fieldState: { error } }) => {
            // console.log('value and typeofValue', value, typeof value);

            // console.log('ERROR MESSAGE OF TEXTFIELD', error);
            return (
              <Box
                // sx={{ display: 'flex', alignItems: 'center', justifyContent: 'center' }}
                sx={{
                  // height: 80,
                  display: 'flex',
                  alignItems: 'center',
                  justifyContent: 'center'
                }}>
                <Checkbox
                  // checked={params.row.selected}
                  // checked={JSON.parse(value)}
                  checked={!value ? false : JSON.parse(value)}
                  onChange={(e) => {
                    // console.log('lets test event', e);
                    onChange(e);
                    handleCellEdit(params.row.id - 1, 'selected', e.target.checked);
                    handleRowSelection(params.row.id);
                  }}
                  sx={{ color: 'black' }}
                />
                {!error && <Box sx={{ minHeight: 10 }} />}
              </Box>
            );
          }}
        />
      )
    },
    {
      field: `${teamGroup.data[0].id}`,
      headerName: `${teamGroup.data[0].name}`,
      sortable: false,
      flex: 1,
      renderCell: (params) => (
        <Controller
          name={`submission.teamMembers.${params.id - 1}.DE060`}
          control={control}
          render={({ field: { onChange, onBlur, value }, fieldState: { error } }) => {
            return (
              <Box
                sx={{
                  width: 1
                }}>
                {textFieldRef.current && isTextOverflowing(textFieldRef.current) ? (
                  <Tooltip title={value}>
                    <StyledTextField
                      autoFocus
                      fullWidth
                      sx={{ m: 0.5 }}
                      variant="outlined"
                      value={value}
                      inputRef={textFieldRef}
                      onKeyDown={(event) => {
                        event.stopPropagation();
                        // console.log("Key down: ", event.key);
                      }}
                      // autoFocus={params.rowIndex === rows.length - 1}
                      // inputRef={inputRef}
                      onChange={(e) => {
                        onChange(e);
                        handleCellEdit(params.row.id - 1, 'DE060', e.target.value);
                      }}
                      onBlur={(event) => {
                        onChange(event.target.value.trim());
                        onBlur(event);
                      }}
                      error={!!error}
                      // error={Boolean(error?.submission?.teamMembers?.[params.row.id]?.DE060)}
                      InputLabelProps={{ style: { pointerEvents: 'auto' } }}
                      inputProps={{
                        style: {
                          textOverflow: 'ellipsis',
                          whiteSpace: 'nowrap',
                          overflow: 'hidden'
                        }
                      }}
                    />
                  </Tooltip>
                ) : (
                  <StyledTextField
                    autoFocus={autoFoucusFlag}
                    fullWidth
                    sx={{ m: 0.5 }}
                    variant="outlined"
                    value={value}
                    inputRef={textFieldRef}
                    onKeyDown={(event) => {
                      event.stopPropagation();
                      // console.log("Key down: ", event.key);
                    }}
                    // autoFocus={params.rowIndex === rows.length - 1}
                    // inputRef={inputRef}
                    onChange={(e) => {
                      onChange(e);
                      handleCellEdit(params.row.id - 1, 'DE060', e.target.value);
                    }}
                    onBlur={(event) => {
                      onChange(event.target.value.trim());
                      onBlur(event);
                    }}
                    error={!!error}
                    // error={Boolean(error?.submission?.teamMembers?.[params.row.id]?.DE060)}
                    InputLabelProps={{ style: { pointerEvents: 'auto' } }}
                    inputProps={{
                      style: { textOverflow: 'ellipsis', whiteSpace: 'nowrap', overflow: 'hidden' }
                    }}
                  />
                )}
                {/*<StyledTextField*/}
                {/*  autoFocus*/}
                {/*  fullWidth*/}
                {/*  sx={{ m: 0.5 }}*/}
                {/*  variant="outlined"*/}
                {/*  value={value}*/}
                {/*  inputRef={textFieldRef}*/}
                {/*  onKeyDown={(event) => {*/}
                {/*    event.stopPropagation();*/}
                {/*    // console.log("Key down: ", event.key);*/}
                {/*  }}*/}
                {/*  // autoFocus={params.rowIndex === rows.length - 1}*/}
                {/*  // inputRef={inputRef}*/}
                {/*  onChange={(e) => {*/}
                {/*    onChange(e);*/}
                {/*    handleCellEdit(params.row.id - 1, 'DE060', e.target.value);*/}
                {/*  }}*/}
                {/*  onBlur={(event) => {*/}
                {/*    onChange(event.target.value.trim());*/}
                {/*    onBlur(event);*/}
                {/*  }}*/}
                {/*  error={!!error}*/}
                {/*  // error={Boolean(error?.submission?.teamMembers?.[params.row.id]?.DE060)}*/}
                {/*  InputLabelProps={{ style: { pointerEvents: 'auto' } }}*/}
                {/*  inputProps={{*/}
                {/*    style: { textOverflow: 'ellipsis', whiteSpace: 'nowrap', overflow: 'hidden' }*/}
                {/*  }}*/}
                {/*/>*/}
                {error && <FormHelperText error>{error.message}</FormHelperText>}
                {!error && <Box sx={{ minHeight: 10 }} />}
              </Box>
            );
          }}
        />
      )
    },
    {
      field: `${teamGroup.data[1].id}`,
      headerName: `${teamGroup.data[1].name}`,
      sortable: false,
      flex: 2,
      renderCell: (params) => (
        <Controller
          name={`submission.teamMembers.${params.id - 1}.DE061`}
          control={control}
          render={({ field: { onChange, value }, fieldState: { error } }) => {
            // console.log(value);
            const fieldValue =
              value && value.length > 0
                ? teamMemberOptions.filter((item) => value.includes(item.id))
                : [];
            // const fieldValue = [
            //   value.length > 0 ? countries.filter((item) => value.includes(item.id)) : null
            // ];
            // console.log('lets test fieldValue', fieldValue);

            // console.log('lets check params', params);

            return (
              <Box
                sx={{
                  // height: 80,
                  width: 1
                }}>
                <Autocomplete
                  // disablePortal
                  id="combo-box-demo"
                  // options={countries}
                  options={teamMemberOptions}
                  fullWidth
                  multiple
                  disablePortal={false}
                  onInputChange={handleInputChange}
                  getOptionLabel={(option) => {
                    // console.log('lets test option', option);
                    return option.name;
                  }}
                  // value={value}
                  value={fieldValue}
                  // getOptionLabel={(option) => {
                  //   // console.log(option);
                  //   // return countries.find((el) => el.id === option);
                  //
                  //   return option.label;
                  // }}
                  // value={[]}
                  // value={value.length > 0 ? countries.filter((item) => value.includes(item.id)) : []}
                  isOptionEqualToValue={(options, value) => {
                    // console.log('testing optins and vales', options, value);
                    return options.id === value.id;
                  }}
                  sx={{
                    m: 0.5,
                    '& .MuiAutocomplete-tag': {
                      maxWidth: 132,
                      whiteSpace: 'nowrap',
                      overflow: 'hidden',
                      textOverflow: 'ellipsis'
                    }
                  }}
                  onChange={(e, newValue) => {
                    // console.log('NEWVALUE multiselect', newValue);
                    let ids;
                    if (newValue === null || newValue.length === 0) {
                      ids = [];
                    } else {
                      ids = newValue.map((item) => item.id);
                    }
                    onChange(ids);
                    handleCellEdit(params.row.id - 1, 'DE061', ids);
                  }}
                  renderInput={(params) => <TextField {...params} />}
                  renderTags={(selectedItems, getTagProps) =>
                    selectedItems.map((item, index) => (
                      // eslint-disable-next-line react/jsx-key
                      <Chip
                        label={item.name}
                        title={item.name}
                        {...getTagProps({ index })}
                        // sx={{
                        //   maxWidth: '100px',
                        //   whiteSpace: 'nowrap',
                        //   overflow: 'hidden',
                        //   textOverflow: 'ellipsis'
                        // }}
                      />
                    ))
                  }
                  // renderTags={(selectedItems, getTagProps) =>
                  //   selectedItems.length > 1 ? (
                  //     <Chip label={`${selectedItems.length} items selected`} />
                  //   ) : (
                  //     selectedItems.map((item, index) => (
                  //       // eslint-disable-next-line react/jsx-key
                  //       <Chip label={item.label} {...getTagProps({ index })} />
                  //     ))
                  //   )
                  // }
                />
                {/*{error && (*/}
                {/*  <FormHelperText sx={{ border: '1px solid green' }} error>*/}
                {/*    {error.message}*/}
                {/*  </FormHelperText>*/}
                {/*)}*/}
                {!error && <Box sx={{ minHeight: 10 }} />}
              </Box>
            );
          }}
        />
      )
    },
    {
      field: `${teamGroup.data[2].id}`,
      headerName: `${teamGroup.data[2].name}`,
      sortable: false,
      flex: 1,
      renderCell: (params) => (
        <Controller
          name={`submission.teamMembers.${params.id - 1}.DE062`}
          control={control}
          render={({ field: { onChange, value }, fieldState: { error } }) => {
            // console.log('lets see teamGroup', teamGroup);
            // console.log('lets print value', value);

            return (
              <Box
                sx={{
                  // height: 80,
                  width: 1
                }}>
                <Autocomplete
                  disablePortal={false}
                  id="combo-box-demo"
                  options={teamGroup.data[2].list ? teamGroup.data[2].list : countries}
                  // options={
                  //   teamGroup.data[2].list
                  //     ? teamGroup.data[2].list.sort((a, b) => a.name.localeCompare(b.name))
                  //     : countries
                  // }
                  fullWidth
                  // value={
                  //   value !== '' && value !== null
                  //     ? countries.find((list) => {
                  //         return value === list.id;
                  //       })
                  //     : null
                  // }
                  value={
                    value !== 0
                      ? teamGroup.data[2].list
                        ? teamGroup.data[2].list.find((list) => {
                            return value === list.id;
                          })
                        : countries
                      : null
                  }
                  sx={{ m: 0.5 }}
                  getOptionLabel={(option) => {
                    // console.log('lets test option', option);
                    return option.name;
                  }}
                  // isOptionEqualToValue={(options, value) => {
                  //   console.log('testing optins and values', options, value);
                  //   return options.id === value.id;
                  // }}
                  onChange={(e, newValue) => {
                    // console.log('NEWVALUE', newValue);
                    // if (newValue == null) {
                    //   newValue = 0;
                    // }
                    let myValue = newValue ? newValue.id : 0;
                    onChange(myValue);
                    handleCellEdit(params.row.id - 1, 'DE062', myValue);
                  }}
                  renderInput={(params) => <TextField {...params} />}
                />
                {!error && <Box sx={{ minHeight: 10 }} />}
              </Box>
            );
          }}
        />
      )
    }
  ];

  return (
    <div style={{ height: 'auto', width: '100%' }}>
      <StyledDataGrid
        getRowHeight={() => 'auto'}
        rows={rows}
        columns={columns}
        // sx={{ border: '1px solid green' }}
        hideFooter
        disableColumnMenu
      />
      {/*<Button variant="outlined" onClick={handleAddRow}>*/}
      {/*  Add*/}
      {/*</Button>*/}
      {/*<Button variant="outlined" onClick={handleDeleteRows}>*/}
      {/*  Delete*/}
      {/*</Button>*/}
    </div>
  );
}

export default EditableGrid;
EditableGrid.propTypes = {
  teamGroup: PropTypes.object,
  rows: PropTypes.array,
  setRows: PropTypes.func,
  autoFoucusFlag: PropTypes.bool
};
