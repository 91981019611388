const riskDataElementArray = [
  {
    id: 'DE248',
    name: 'Schedule Risk Rating',
    family: 'risk',
    type: 'Enumerated',
    defaultValue: '',
    placeholder: '',
    validation: {},
    list: [
      {
        id: 1,
        name: 'Low'
      },
      {
        id: 2,
        name: 'Elevated'
      },
      {
        id: 3,
        name: 'High'
      }
    ],
    hasDescription: true,
    description:
      "The Procurement Lead's assessment as to whether award is likely to be achieved on schedule, gauged against the RFx’s listed schedule, or equivalent. Assessments of 'Low' risk indicate award is likely to be achieved on schedule, 'Elevated' risk indicates moderate or critical risks to schedule are likely or certain, and 'High' risk indicates a critical risk to schedule is certain to occur.",
    isDisplayed: true
  },
  {
    id: 'DE249',
    name: 'Schedule Risk Notes',
    family: 'risk',
    type: 'String',
    defaultValue: '',
    placeholder: '',
    validation: {
      max: {
        value: 1000,
        message: 'Schedule Risk Notes cannot have more than 1000 characters.'
      }
    },
    hasDescription: true,
    description:
      'A brief overview of any schedule-related elevated or high risks. Identified risks should include a description following the STAR Method: Situation/Task, Action, Result. Unresolved risks may include description elements as they become available - an identified risk with no current action response nor outcome should include an outline of the situation alone and then be updated with actions and results as the Lead becomes aware of them. Risks whose entire lifecycle occurs prior to reporting should include all description elements at point of recording.',
    isDisplayed: true
  },
  {
    id: 'DE216',
    name: 'Market Participation Risk Rating',
    family: 'risk',
    type: 'Enumerated',
    defaultValue: '',
    placeholder: '',
    validation: {},
    list: [
      {
        id: 1,
        name: 'Low'
      },
      {
        id: 2,
        name: 'Elevated'
      },
      {
        id: 3,
        name: 'High'
      }
    ],
    hasDescription: true,
    description:
      "The Procurement Lead's assessment as to whether the number of engaged vendors are capable of ensuring sufficient and appropriate levels of competition. Assessments of 'Low' risk indicate an appropriate level of competitiveness has been or is likely to be achieved, 'Elevated' risk indicates moderate or critical risks to participation are likely or certain, and 'High' risk indicates a critical risk to participation is certain to occur.",
    isDisplayed: true
  },
  {
    id: 'DE217',
    name: 'Market Participation Risk Notes',
    family: 'risk',
    type: 'String',
    defaultValue: '',
    placeholder: '',
    validation: {
      max: {
        value: 1000,
        message: 'Market Participation Risk Notes cannot have more than 1000 characters.'
      }
    },
    hasDescription: true,
    description:
      'A brief overview of any market participation-related elevated or high risks. Identified risks should include a description following the STAR Method: Situation/Task, Action, Result. Unresolved risks may include description elements as they become available - an identified risk with no current action response nor outcome should include an outline of the situation alone and then be updated with actions and results as the Lead becomes aware of them. Risks whose entire lifecycle occurs prior to reporting should include all description elements at point of recording.',
    isDisplayed: true
  },
  {
    id: 'DE250',
    name: 'Budget Risk Rating',
    family: 'risk',
    type: 'Enumerated',
    defaultValue: '',
    placeholder: '',
    validation: {},
    list: [
      {
        id: 1,
        name: 'Low'
      },
      {
        id: 2,
        name: 'Elevated'
      },
      {
        id: 3,
        name: 'High'
      }
    ],
    hasDescription: true,
    description:
      "The Procurement Lead's assessment as to whether the targeted budget is exceeded by bids received. Assessments of 'Low' risk indicate award is likely to be achieved within budget, 'Elevated' risk indicates moderate or critical risks to budget are likely or certain, and 'High' risk indicates a critical risk to budget is certain to occur.",
    isDisplayed: true
  },
  {
    id: 'DE251',
    name: 'Budget Risk Notes',
    family: 'risk',
    type: 'String',
    defaultValue: '',
    placeholder: '',
    validation: {
      max: {
        value: 1000,
        message: 'Budget Risk Notes cannot have more than 1000 characters'
      }
    },
    hasDescription: true,
    description:
      'A brief overview of any budget-related elevated or high risks. Identified risks should include a description following the STAR Method: Situation/Task, Action, Result. Unresolved risks may include description elements as they become available - an identified risk with no current action response nor outcome should include an outline of the situation alone and then be updated with actions and results as the Lead becomes aware of them. Risks whose entire lifecycle occurs prior to reporting should include all description elements at point of recording.',
    isDisplayed: true
  },
  {
    id: 'DE220',
    name: 'Administrative Risk Rating',
    family: 'risk',
    type: 'Enumerated',
    defaultValue: '',
    placeholder: '',
    validation: {},
    list: [
      {
        id: 1,
        name: 'Low'
      },
      {
        id: 2,
        name: 'Elevated'
      },
      {
        id: 3,
        name: 'High'
      }
    ],
    hasDescription: true,
    description:
      "The Procurement Lead's assessment as to whether critical administrative resources (technological systems or platforms, Standard Operating Procedures, or other tools/assets) are available and in good standing. Assessments of 'Low' risk indicate the no additional time is required to address or remediate the effects of unavailable or administrative assets in poor standing, 'Elevated' risk indicates that a moderate or critical amount of time is likely or certain to be required to deal with such assets, and 'High' risk indicates a critical amount of time is certain to be required.",
    isDisplayed: true
  },
  {
    id: 'DE221',
    name: 'Administrative Risk Notes',
    family: 'risk',
    type: 'String',
    defaultValue: '',
    placeholder: '',
    validation: {
      max: {
        value: 1000,
        message: 'Policy, Process, Procedure Risk Notes cannot have more than 1000 characters'
      }
    },
    hasDescription: true,
    description:
      'A brief overview of any administration-related elevated or high risks. Identified risks should include a description following the STAR Method: Situation/Task, Action, Result. Unresolved risks may include description elements as they become available - an identified risk with no current action response nor outcome should include an outline of the situation alone and then be updated with actions and results as the Lead becomes aware of them. Risks whose entire lifecycle occurs prior to reporting should include all description elements at point of recording.',
    isDisplayed: true
  }
];
export default riskDataElementArray;
